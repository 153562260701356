<template>
  <section>
    <p class="subtitle">75% rouge, 25% blanc 😱</p>
    <p class="">
      Irouleguy, pour commencer, c'est par là. Et vu que vous êtes incollable
      sur les différents pôles territoriaux du pays basque, vous savez que c'est
      rattaché à Garazi Baigorri.
    </p>
    <div class="columns">
      <div class="column">
        <p class="">
          La carte présente les différentes parcelles de vignes de l'appelation
          Irouléguy. N'hésitez pas à zoomer et cliquer sur les polygones pour
          avoir plus d'informations !
        </p>
        <iframe
          src="https://zabal-agriculture.opendata-paysbasque.fr/explore/embed/dataset/vin-dirouleguy-aop-localisation-vignes-plantees-2021/map/?disjunctive.code_insee&disjunctive.cepage&disjunctive.appellation&disjunctive.categories&disjunctive.types_de_parcelles&disjunctive.bio&location=12,43.18961,-1.26774&basemap=jawg.streets&static=false&datasetcard=false&scrollWheelZoom=false"
          width="100%"
          height="450"
          frameborder="0"
        ></iframe>
      </div>
      <div class="column">
        <p class="">
          Ce pôle est le principal terroir pour la vigne avec en 2020, plus de
          la moitié des surfaces en vigne. Découvrir la répartition des surfaces
          en vigne dans les différents pôles du pays basques.
          <iframe
            src="https://zabal-agriculture.opendata-paysbasque.fr/chart/embed/?dataChart=eyJ0aW1lc2NhbGUiOiIiLCJxdWVyaWVzIjpbeyJjaGFydHMiOlt7ImFsaWduTW9udGgiOnRydWUsInR5cGUiOiJwaWUiLCJmdW5jIjoiU1VNIiwieUF4aXMiOiJ2YWxldXIiLCJzY2llbnRpZmljRGlzcGxheSI6ZmFsc2UsImNvbG9yIjoicmFuZ2UtY3VzdG9tIiwicG9zaXRpb24iOiJjZW50ZXIiLCJ5TGFiZWxPdmVycmlkZSI6IlN1cmZhY2UgZGUgdmlnbmVzIHBhciBwXHUwMEY0bGUgdGVycml0b3JpYWwiLCJkaXNwbGF5VW5pdHMiOmZhbHNlfV0sImNvbmZpZyI6eyJkYXRhc2V0IjoicmdhMjAyMF9kYXRhdml6X2NoYWxsZW5nZSIsIm9wdGlvbnMiOnsiZGlzanVuY3RpdmUuZWNoZWxsZSI6dHJ1ZSwiZGlzanVuY3RpdmUuY2F0ZWdvcmllIjp0cnVlLCJkaXNqdW5jdGl2ZS50eXBlIjp0cnVlLCJkaXNqdW5jdGl2ZS5hbm5lZSI6dHJ1ZSwiZGlzanVuY3RpdmUuYWdlXzUiOnRydWUsInEiOiJ0eXBlOnZpZ25lcyIsInJlZmluZS5lY2hlbGxlIjpbIkFtaWt1emUiLCJDb3RlIEJhc3F1ZSBBZG91ciIsIkVycm9iaSIsIkdhcmF6aSBCYWlnb3JyaSIsIklob2xkaSBPenRpYmFycmUiLCJOaXZlIEFkb3VyIiwiUGF5cyBkJ0hhc3BhcnJlbiIsIlBheXMgZGUgQmlkYWNoZSIsIlNvdWxlIFhpYmVyb2EiLCJTdWQgUGF5cyBCYXNxdWUiXSwicmVmaW5lLmFubmVlIjoiMjAyMCJ9fSwieEF4aXMiOiJlY2hlbGxlIiwibWF4cG9pbnRzIjo1MCwic29ydCI6InNlcmllMS0xIiwic2VyaWVzQnJlYWtkb3duIjoiIiwic2VyaWVzQnJlYWtkb3duVGltZXNjYWxlIjoiIn1dLCJhbGlnbk1vbnRoIjp0cnVlLCJkaXNwbGF5TGVnZW5kIjp0cnVlfQ%3D%3D"
            width="100%"
            height="350"
            frameborder="0"
          ></iframe>
        </p>
        <p class="">
          On peut cependant noter qu'en 10 ans, plus de 25% des surfaces en
          vigne ont diminué sur l'ensemble du territoire. Profitons encore des
          surfaces existantes pour continuer la production de ce savoureux
          liquide !
        </p>
        <br />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "QuestDetail3",
  props: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
