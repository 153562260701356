<template>
  <div>
    <div class="has-text-centered">
      <section class="hero" v-if="currentQuestionIndex === 0">
        <div class="hero-body">
          <p class="title">Challenge ton (agri)basque!</p>
          <br />
          <p class="subtitle">
            Benetako euskaldun batek bakarrik lor dezakeen erronka!
          </p>
        </div>
        <b-container class="has-text-centered">
          <b-button
            type="is-success"
            size="is-large"
            @click="nextQuestion(currentQuestionIndex)"
            >Commencer le quizz</b-button
          >
        </b-container>
      </section>
    </div>

    <question-answer
      v-if="
        currentQuestionIndex !== 0 &&
        currentQuestionIndex < questionAnswers.length
      "
      :question="question"
      @nextQuestion="nextQuestion"
    >
    </question-answer>

    <div
      class="has-text-centered"
      v-if="currentQuestionIndex === questionAnswers.length"
    >
      <section class="hero">
        <div class="hero-body">
          <p class="title">Merci pour votre participation !</p>
          <p class="title">
            <b-progress
              size="is-large"
              :value="score"
              :max="4"
              :type="color"
              show-value
            >
              Votre score est de {{ score }} sur
              {{ questionAnswers.length - 1 }} questions !
            </b-progress>
          </p>
          <br />
          <p class="subtitle">{{ results[score] }}</p>
          <br />
          <p class="subtitle">
            Si cela vous a plu, n'hésitez pas à partager ce petit quizz et à
            consulter les jeux de données présents pour découvrir toujours plus
            d'informations sur l'agriculture du pays basque !!
          </p>
          <p>
            <a
              href="https://zabal-agriculture.opendata-paysbasque.fr/pages/categories_donnees/"
              target="_blank"
            >
              <b-button type="is-info"> Accès au portail Zabal </b-button>
            </a>
          </p>
          <br />
          <b-button type="is-success" @click="copyLink()"
            >Copier l'adresse du QUIZZ !</b-button
          >
          &nbsp;
          <b-button type="is-success" @click="restart()"
            >Recommencer !</b-button
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import QuestionAnswer from "./components/QuestionAnswer.vue";

export default {
  name: "App",
  components: {
    QuestionAnswer,
  },
  computed: {
    color() {
      switch (this.score) {
        case 0:
          return "is-danger";
        case 1:
          return "is-warning";
        case 2:
          return "is-info";
        case 3:
          return "is-success";
        case 4:
          return "is-success";
        default:
          return "is-success";
      }
    },
    question() {
      console.log("click");
      return this.questionAnswers.find(
        (q) => q.id === this.currentQuestionIndex
      );
    },
    score() {
      let s = 0;
      this.reponses.forEach((r) => {
        let question = this.questionAnswers.find((q) => q.id === r.index);
        r.point === question.solution ? s++ : null;
      });
      return s;
    },
  },
  data() {
    return {
      reponses: [],
      questionAnswers: [
        {
          id: 0,
          question: null,
          reponse: null,
        },
        {
          id: 1,
          question: "Quels sont les agris Basques les plus à l'Ouest ?",
          choix: [
            {
              i: "A",
              lib: "Les agris de Hendaye",
            },
            {
              i: "B",
              lib: "Les agris de Mendionde",
            },
            {
              i: "C",
              lib: "Les agris de Saint-Étienne-de-Baïgorry",
            },
            {
              i: "D",
              lib: "Les agris de Anglet",
            },
          ],
          solution: "A",
        },
        {
          id: 2,
          question: "Le piment d'Espelette, ça pique à combien ?",
          choix: [
            {
              i: "A",
              lib: "Doux",
            },
            {
              i: "B",
              lib: "Chaud",
            },
            {
              i: "C",
              lib: "Ardent",
            },
            {
              i: "D",
              lib: "La réponse... torride !",
            },
          ],
          solution: "B",
        },
        {
          id: 3,
          question:
            "Le pays basque, c'est un doux mélange de blanc et de rouge, mais quelles sont les proportions pour le Vin d'Irouleguy ?",
          choix: [
            {
              i: "A",
              lib: "Il y a du vin à Irouleguy ?!",
            },
            {
              i: "B",
              lib: "75% rouge, 25% blanc",
            },
            {
              i: "C",
              lib: "Fifty-fifty",
            },
            {
              i: "D",
              lib: "25% rouge, 75% blanc",
            },
          ],
          solution: "B",
        },
        {
          id: 4,
          question:
            "Combien de jours passent les brebis laitières de races locales au pâturage pour la production de cet excellent fromage AOP Ossau-Iraty ?",
          choix: [
            {
              i: "A",
              lib: "100 jours",
            },
            {
              i: "B",
              lib: "160 jours",
            },
            {
              i: "C",
              lib: "200 jours",
            },
            {
              i: "D",
              lib: "240 jours",
            },
          ],
          solution: "D",
        },
      ],
      currentQuestionIndex: 0,
      showNextQuestion: false,
      results: [
        "Oh-oh, tu as obtenu un score de 0... Mais ne t'inquiète pas, tu as encore toute une ferme de connaissances à explorer au Pays Basque ! 🌄🌾",
        "Avec un score de 1, on dirait que tu as besoin d'un peu plus de temps pour t'immerger dans les merveilles de l'agriculture basque. Ne désespère pas ! 🌽🤓",
        "Avec un score de 2, tu as montré que tu as des compétences agricoles décentes, mais il y a toujours de la place pour la croissance ! 🐄🌿",
        "Avec un score de 3, tu te rapproches du rang de 'Connaisseur du Pays Basque'. Continue à cultiver tes connaissances ! 🌱📚",
        "Félicitations ! Tu as récolté un score parfait de 4, tu es maintenant un expert en agriculture basque ! 🌾🚜",
      ],
    };
  },
  methods: {
    nextQuestion(u) {
      if (u !== 0) {
        this.reponses.push({
          index: this.currentQuestionIndex,
          point: u,
        });
      }
      this.currentQuestionIndex++;
    },
    restart() {
      this.currentQuestionIndex = 0;
      this.showNextQuestion = false;
      this.reponses = [];
    },
    copyLink() {
      const el = document.createElement("textarea");
      el.value = "https://lab.agrig33k.fr/";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$buefy.snackbar.open({
        message: "Le lien a été copié dans votre presse-papier !",
        type: "is-success",
        position: "is-top",
      });
    },
  },
};
</script>

<style>
.title {
  color: #363636;
  font-size: 4rem !important;
  font-weight: 600;
  line-height: 1.125;
}
.subtitle {
  color: #4a4a4a;
  font-size: 2rem !important;
  font-weight: 400;
  line-height: 1.25;
}
.card {
  margin-top: 3%;
  margin-right: 3%;
  margin-left: 3%;
  margin-bottom: 20px;
}
.bottom-right-button {
  /* Set the button to absolute positioning */
  position: absolute;
  bottom: 10px; /* Adjust the distance from the bottom */
  right: 10px; /* Adjust the distance from the right */
}
</style>
