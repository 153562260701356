<template>
  <div>
    <p class="subtitle">240 jours !</p>

    <p class="">
      AOP reconnue depuis 1996, Ossau-Iraty est l'une des quatre AOP présentes.
      Elle valorise l'élevage local avec notamment trois races de brebis
      (source: Site internet AOP Ossau-Iraty). Pour être éligible à l'AOP, les
      animaux doivent pâturer pendant 240j, soit deux tiers de l'année ! Un
      cahier des charges strict, qui garantit la qualité de ce fromage.
    </p>

    <img src="@/assets/brebis.png" alt="Placeholder image" />

    <p>
      Comme dans beaucoup de régions, l'élevage a tendance à reculer dans les
      différents pôles territoriaux du pays basque. Seul un pôle a vu le nombre
      de brebis allaitante augmenter, saurez-vous deviner lequel ?
    </p>
    <iframe
      src="https://zabal-agriculture.opendata-paysbasque.fr/chart/embed/?dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6InJnYS0yMDIwLWRhdGF2aXotY2hhbGxlbmdlLWNvcGllIiwib3B0aW9ucyI6eyJkaXNqdW5jdGl2ZS5lY2hlbGxlIjp0cnVlLCJkaXNqdW5jdGl2ZS5jYXRlZ29yaWUiOnRydWUsImRpc2p1bmN0aXZlLnR5cGUiOnRydWUsImRpc2p1bmN0aXZlLmFubmVlIjp0cnVlLCJkaXNqdW5jdGl2ZS5hZ2VfNSI6dHJ1ZSwic29ydCI6ImVjaGVsbGUiLCJxIjoiYnJlYmlzIiwicmVmaW5lLnR5cGUiOiJicmViaXMgbVx1MDBFOHJlcyBsYWl0aVx1MDBFOHJlcyIsInJlZmluZS5lY2hlbGxlIjpbIkFtaWt1emUiLCJFcnJvYmkiLCJHYXJhemkgQmFpZ29ycmkiLCJJaG9sZGkgT3p0aWJhcnJlIiwiTml2ZSBBZG91ciIsIlBheXMgZCdIYXNwYXJyZW4iLCJQYXlzIGRlIEJpZGFjaGUiLCJTb3VsZSBYaWJlcm9hIiwiU3VkIFBheXMgQmFzcXVlIl19fSwiY2hhcnRzIjpbeyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoiY29sdW1uIiwiZnVuYyI6IkFWRyIsInlBeGlzIjoidGV0ZXMiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiJyYW5nZS1BY2NlbnQiLCJ5TGFiZWxPdmVycmlkZSI6IlBcdTAwRjRsZSB0ZXJyaXRvcmlhbCIsImRpc3BsYXlVbml0cyI6ZmFsc2UsImRpc3BsYXlWYWx1ZXMiOnRydWUsImRpc3BsYXlTdGFja1ZhbHVlcyI6ZmFsc2V9XSwieEF4aXMiOiJlY2hlbGxlIiwibWF4cG9pbnRzIjo1MCwic29ydCI6IiIsInNlcmllc0JyZWFrZG93biI6ImFubmVlIiwic2VyaWVzQnJlYWtkb3duVGltZXNjYWxlIjoiIiwiY2F0ZWdvcnlDb2xvcnMiOnsiMjAxMCI6IiNFNTM1MkUiLCIyMDIwIjoiI0U4NzI3MiJ9fV0sInRpbWVzY2FsZSI6IiIsImRpc3BsYXlMZWdlbmQiOmZhbHNlLCJhbGlnbk1vbnRoIjp0cnVlLCJzaW5nbGVBeGlzIjp0cnVlLCJzaW5nbGVBeGlzTGFiZWwiOiJOb20gZGUgdFx1MDBFQXRlcyBkZSBjaGVwdGVsIGJyZWJpcyBhbGxhaXRhbnRlcyIsInlSYW5nZU1heCI6IjEyNTAwMCJ9"
      width="100%"
      height="450"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "QuestDetail4",
  props: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
