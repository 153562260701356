<template>
  <section class="hero">
    <div class="">
      <p class="subtitle">Chaud !</p>

      <p class="">
        Selon l'échelle de Scoville, le piment d'Espelette est considéré comme
        un piment “chaud”. Parfait donc pour relever les plats, sans avoir la
        gorge en feu.
      </p>
      <div class="columns">
        <div class="column">
          <p class="subtitle">Près de chez vous ?</p>
          <p>
            Le piment d'espelette est produit sur 10 communes, au sud de
            Bayonne. Découvrez s'il y a une parcelle près de chez vous.
          </p>
          <iframe
            src="https://zabal-agriculture.opendata-paysbasque.fr/explore/embed/dataset/piment-despelette-aop-parcelles-utilisees-par-les-producteurs-2021/map/?location=12,43.35689,-1.40127&basemap=jawg.streets&static=false&datasetcard=false&scrollWheelZoom=false"
            width="100%"
            height="450"
            frameborder="0"
          ></iframe>
        </div>
        <div class="column">
          <p class="subtitle">Envie d'en savoir plus ?</p>
          <p class="">
            La filière connaît un fort essor depuis ces vingt dernières années,
            en passant de 60 producteurs à plus de 200 ans, en vingt ans
            seulement. Pour découvrir les autres chiffres clés de la filière,
            <a
              href="https://zabal-agriculture.opendata-paysbasque.fr/pages/piments-espelette/"
              target="_blank"
              >c'est par ici</a
            >.
          </p>
          <iframe
            src="https://zabal-agriculture.opendata-paysbasque.fr/explore/embed/dataset/piment-despelette-information-generale-2001-2020/analyze/?disjunctive.campagne&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6InBpbWVudC1kZXNwZWxldHRlLWluZm9ybWF0aW9uLWdlbmVyYWxlLTIwMDEtMjAyMCIsIm9wdGlvbnMiOnsiZGlzanVuY3RpdmUuY2FtcGFnbmUiOnRydWV9fSwiY2hhcnRzIjpbeyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoiY29sdW1uIiwiZnVuYyI6IlNVTSIsInlBeGlzIjoic3VwZXJmaWNpZV9wbGFudGVlX2VuX2hhIiwic2NpZW50aWZpY0Rpc3BsYXkiOnRydWUsImNvbG9yIjoiI0NBMzIzNCJ9XSwieEF4aXMiOiJjYW1wYWduZSIsIm1heHBvaW50cyI6NTAsInNvcnQiOiIifV0sInRpbWVzY2FsZSI6IiIsImRpc3BsYXlMZWdlbmQiOnRydWUsImFsaWduTW9udGgiOnRydWV9"
            width="100%"
            height="450"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: "QuestDetail2",
  props: {},
  mounted() {
    var map2 = L.map("map2").setView([43.37172, 0], 8);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "Map data &copy; OpenStreetMap contributors",
      maxZoom: 18,
    }).addTo(map2);
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#map2 {
  height: 400px;
  width: 100%;
}
</style>
