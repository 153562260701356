<template>
  <span>
    <p class="subtitle">Les agris de Hendaye</p>
    <p class="">
      Ce sont bien les agriculteurs de Hendaye qui sont les plus.. à l'Ouest,
      rattachés au pôle territorial “Pays basque Sud” ! Quand au plus à l'est..
      on hésite encore entre les agriculteurs de L'Hôpital-Saint-Blaise ou de
      Sainte-Engrâce.. mais c'est bien le pôle territorial de Soule Xiberoa.
    </p>
    <p class="">
      Envie d'en savoir plus ?
      <a
        href="https://zabal-agriculture.opendata-paysbasque.fr/explore/dataset/contour_capb_poles_territoriaux/map/?disjunctive.nom&location=10,43.24972,-1.26911&basemap=jawg.streets"
        target="_blank"
      >
        Découvrir les 10 pôles territoriaux du pays basque.
      </a>
    </p>
    <div id="map1"></div>
  </span>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Icon } from "leaflet";

export default {
  name: "QuestDetail1",
  props: {},
  mounted() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });

    var map1 = L.map("map1").setView([43.2, -1], 8);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "Map data &copy; OpenStreetMap contributors",
      maxZoom: 18,
    }).addTo(map1);
    let m = L.marker([43.37172, -1.77382]).addTo(map1);
    m.bindPopup("Hendaye").openPopup();
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#map1 {
  height: 400px;
  width: 100%;
}
</style>
