<template>
  <div>
    <div class="card">
      <div class="card-image">
        <figure class="imghead image">
          <img :src="getImgUrl(question.id)" alt="Placeholder image" />
        </figure>
      </div>
      <div class="card-content">
        <p class="subtitle">{{ question.question }}</p>

        <div class="content">
          <div class="columns" v-if="!reponse">
            <div class="column">
              <div>
                <b-field v-for="choix in question.choix" v-bind:key="choix.i">
                  <b-radio
                    v-model="utilisateur"
                    size="is-large"
                    :native-value="choix.i"
                    :disabled="prereponse"
                    :style="{
                      color:
                        prereponse && question.solution === choix.i
                          ? 'green'
                          : prereponse && choix.i === utilisateur
                          ? 'red'
                          : '',
                    }"
                  >
                    {{ choix.lib }}
                  </b-radio>
                </b-field>
              </div>
            </div>
            <div class="column"></div>
          </div>

          <quest-detail-1 v-if="question.id === 1 && reponse"></quest-detail-1>
          <quest-detail-2 v-if="question.id === 2 && reponse"></quest-detail-2>
          <quest-detail-3 v-if="question.id === 3 && reponse"></quest-detail-3>
          <quest-detail-4 v-if="question.id === 4 && reponse"></quest-detail-4>
        </div>
      </div>
    </div>

    <section class="hero">
      <div class="has-text-centered" style="margin-bottom: 20px">
        <b-button
          type="is-success"
          size="is-large"
          v-if="!reponse"
          @click="showAnswer()"
          >Voir la réponse !</b-button
        >
        <b-button
          type="is-primary"
          size="is-large"
          v-if="reponse"
          @click="nextQuestion()"
        >
          {{ question.id === 4 ? "Voir les résultats" : "Question suivante" }}
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import QuestDetail1 from "./QuestDetail1.vue";
import QuestDetail2 from "./QuestDetail2.vue";
import QuestDetail3 from "./QuestDetail3.vue";
import QuestDetail4 from "./QuestDetail4.vue";

export default {
  components: { QuestDetail1, QuestDetail2, QuestDetail3, QuestDetail4 },
  name: "QuestionAnswer",
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      utilisateur: null,
      reponse: false,
      prereponse: false,
    };
  },
  methods: {
    getImgUrl(id) {
      return require("@/assets/ban" + id + ".png");
    },
    showAnswer() {
      if (this.utilisateur === null) {
        this.$buefy.snackbar.open({
          message: "Sélectionnez une réponse !",
          type: "is-warning",
          position: "is-top",
        });
      } else {
        this.prereponse = !this.prereponse;

        let color;
        let info;

        if (this.utilisateur === this.question.solution) {
          color = "is-success";
          info = "Bonne réponse 😀";
        } else {
          color = "is-danger";
          info = "Mauvaise réponse 🙁";
        }

        this.$buefy.toast.open({
          duration: 2000,
          message: info,
          position: "is-top",
          type: color,
        });

        setTimeout(() => {
          this.prereponse = !this.prereponse;
          this.reponse = !this.reponse;
        }, 2000);
      }
    },
    nextQuestion() {
      this.$emit("nextQuestion", this.utilisateur);
      this.reponse = false;
      this.utilisateur = null;
    },
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
