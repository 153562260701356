import { render, staticRenderFns } from "./QuestDetail2.vue?vue&type=template&id=4deb8229&scoped=true&"
import script from "./QuestDetail2.vue?vue&type=script&lang=js&"
export * from "./QuestDetail2.vue?vue&type=script&lang=js&"
import style0 from "./QuestDetail2.vue?vue&type=style&index=0&id=4deb8229&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4deb8229",
  null
  
)

export default component.exports