var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-image"},[_c('figure',{staticClass:"imghead image"},[_c('img',{attrs:{"src":_vm.getImgUrl(_vm.question.id),"alt":"Placeholder image"}})])]),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.question.question))]),_c('div',{staticClass:"content"},[(!_vm.reponse)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',_vm._l((_vm.question.choix),function(choix){return _c('b-field',{key:choix.i},[_c('b-radio',{style:({
                    color:
                      _vm.prereponse && _vm.question.solution === choix.i
                        ? 'green'
                        : _vm.prereponse && choix.i === _vm.utilisateur
                        ? 'red'
                        : '',
                  }),attrs:{"size":"is-large","native-value":choix.i,"disabled":_vm.prereponse},model:{value:(_vm.utilisateur),callback:function ($$v) {_vm.utilisateur=$$v},expression:"utilisateur"}},[_vm._v(" "+_vm._s(choix.lib)+" ")])],1)}),1)]),_c('div',{staticClass:"column"})]):_vm._e(),(_vm.question.id === 1 && _vm.reponse)?_c('quest-detail-1'):_vm._e(),(_vm.question.id === 2 && _vm.reponse)?_c('quest-detail-2'):_vm._e(),(_vm.question.id === 3 && _vm.reponse)?_c('quest-detail-3'):_vm._e(),(_vm.question.id === 4 && _vm.reponse)?_c('quest-detail-4'):_vm._e()],1)])]),_c('section',{staticClass:"hero"},[_c('div',{staticClass:"has-text-centered",staticStyle:{"margin-bottom":"20px"}},[(!_vm.reponse)?_c('b-button',{attrs:{"type":"is-success","size":"is-large"},on:{"click":function($event){return _vm.showAnswer()}}},[_vm._v("Voir la réponse !")]):_vm._e(),(_vm.reponse)?_c('b-button',{attrs:{"type":"is-primary","size":"is-large"},on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v(" "+_vm._s(_vm.question.id === 4 ? "Voir les résultats" : "Question suivante")+" ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }